import get from 'lodash/get';
import { runMingo, unFlatten } from '@buddy-technology/ion-helpers';
import hasCalculate from './hasCalculate';

const clean = (str) => str.replace(/[&/\\#,+()$~%'":*?<>{}]/g, '').trim();
/**
 * Helper function for getting DynoMark value
 * @param  {String} dyno - the dynamic markdown substring
 * @param  {Object} state - form or application state
 * @param  {Object} variables - variables needed for processing
 * @returns the processed value
 */
const getValue = (dyno, state, variables, offeringOptions) => {
	const key = clean(dyno);
	const formattedState = unFlatten(state);
	const raw = get({ variables, offeringOptions, ...formattedState }, key);

	if (hasCalculate(raw)) {
		// keeping the noise down, we're not going to log errors here since a lot of dynomark is contingent on user state
		const { value } = runMingo(raw._calculate, { ...formattedState, variables, offeringOptions });
		if ([undefined, null].includes(value)) {
			return '';
			// if there's no value, return empty string (so markdown doesn't render 'undefined' or 'null')
			// NaN will still be returned here, so devs can catch/correct those issues sooner.
		}
		return value;
	}
	return raw;
};

const getAllowedValue = (value) => {
	const type = typeof value;
	const disallowedTypes = ['symbol', 'undefined', 'object', 'function'];
	if (disallowedTypes.includes(type)) {
		return '';
	}
	if (type === 'number' && !Number.isFinite(value)) {
		return '';
	}
	return value;
};

/**
 * Processes dynamic markdown (DynoMark) and returns the interpolated string
 * Currently can only process static references or mingo calculations.
 * @param  {String} text - the raw DynoMark* text to be processed
 * @param  {Object} [state = {}] - form/application state object
 * @param  {Object} [variables = {}] - any variables needed for processing
 * @returns {String} - the processed markdown string.
 */
function getDynoMark(text, state = {}, variables = {}, offeringOptions = {}) {
	const dynos = text.match(/\{\{(.*?)\}\}/g);
	let processed = text;
	dynos?.forEach((el) => {
		const rawValue = getValue(el, state, variables, offeringOptions);
		const value = getAllowedValue(rawValue);
		processed = processed.replace(el, value);
	});
	return processed;
}

export default getDynoMark;
