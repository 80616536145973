import { runMingo, unFlatten } from '@buddy-technology/ion-helpers';
import { useWatch } from 'react-hook-form';

const generateDynamicOptionsNew = ({
	dynamicOptionLabels, getValues, variables, control, offeringOptions,
}) => {
	const valuesToWatch = dynamicOptionLabels?.fieldsToWatch ? dynamicOptionLabels?.fieldsToWatch.map((_label) => _label.replace(/\$/g, '').replace(/\./g, '::')) : [];

	if (dynamicOptionLabels?._calculate) {
		const state = getValues();
		const { value: mingoValue, error } = runMingo(dynamicOptionLabels._calculate, { ...unFlatten(state), variables, offeringOptions });
		if (error) {
			// eslint-disable-next-line no-console
			console.log(`Problem calculating dynamic option labels: ${error}`);
			return [];
		}
		const dynamicOptionsToRender = mingoValue?.reduce((acc, _item) => {
			const { value, label } = _item;
			if (label && value && label !== '' && value !== '') {
				/*
					we need to give dynamic options a new unique key when it re-renders
					this addresses a bug with react hook form that cannot update a newly generated option when a previously
					selected option disappears
				*/
				acc.push({ ..._item, key: `${value}${Math.random()}` });
			}
			return acc;
		}, []);

		useWatch({ control, name: valuesToWatch });

		return dynamicOptionsToRender;
	}
	return [];
};

export default generateDynamicOptionsNew;
