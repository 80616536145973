import React, { useEffect } from 'react';
import PlacesAutocomplete, {
	geocodeByAddress,
} from 'react-places-autocomplete';
import { runMingo, unFlatten } from '@buddy-technology/ion-helpers';
import { useFormState } from 'react-hook-form';
import formatAddressAutocomplete from '../../utils/formatAddressAutocomplete';
import formatAndReplaceAddressAutocompleteValues from '../../utils/formatAndReplaceAddressAutocompleteValues';
import validateAgainstAnotherField from '../../utils/validateAgainstAnotherField';
import { useEventContext, useDataContext } from '../../context';
import loadScript from '../../utils/loadExternalScript';

const AddressAutocomplete = ({
	id,
	htmlId,
	label = null,
	register,
	errors,
	setError,
	clearErrors,
	isEndorsable,
	isRequired,
	placeholder,
	isDisabled,
	helpText,
	getValues,
	viewId,
	control,
	Controller,
	updateAppState,
	flatApplication,
	setValue,
	trigger,
	fieldsToWatch = [],
	placesApiKey,
	...rest
}, ref) => {
	const { eventCallback } = useEventContext();
	const { ion: { variables }, offeringData } = useDataContext();

	const keyToUse = placesApiKey || process.env.REACT_APP_GOOGLE_PLACES_API_KEY; // if key is coming from Offer Element, use it; otherwise, use LOCAL key saved in env

	const SCRIPT = {
		URL: `https://maps.googleapis.com/maps/api/js?key=${keyToUse}&libraries=places&callback=myCallbackFunc`,
		REGEX: /^https:\/\/maps\.googleapis\.com\/maps\/api\/js\?key=[A-Za-z0-9-_]+&libraries=places&callback=myCallbackFunc$/,
	};

	const getScript = async () => {
		try {
			await loadScript(SCRIPT);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	};

	const onError = (status, clearSuggestions) => {
		// eslint-disable-next-line no-console
		console.log('Google Maps API returned error with status: ', status);
		clearSuggestions();
	};

	const valuesToWatch = fieldsToWatch ? fieldsToWatch.map((_label) => _label.replace(/\$/g, '').replace(/\./g, '::')) : [];

	const formStateOfValuesToWatch = useFormState({ control, name: valuesToWatch });
	const errorMessages = validateAgainstAnotherField(valuesToWatch, formStateOfValuesToWatch);
	const errorsInMappedFields = errorMessages.length > 0 ? errorMessages.join(', ') : null;
	const hasErrors = !!errors || !!errorsInMappedFields;
	const errorMessage = errors?.message || errorsInMappedFields || null;

	useEffect(() => {
		if (errorsInMappedFields?.length > 0) {
			setError(id, { type: 'custom', message: errorMessage }, { shouldFocus: true });
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errorMessage });
		}
	}, [errorsInMappedFields]);

	useEffect(() => {
		getScript();
	}, []);

	// this is fine to be separate since we only want to pass along the error type (to protect divulging PII) and 'errors' include all field errors here.
	useEffect(() => {
		if (errors) {
			eventCallback('onValidationError', { elementId: label, timestamp: Date.now(), validationError: errors?.type });
		}
	}, [errors?.message]);

	return (
		<>
			<Controller
				name={id}
				control={control}
				render={({ field }) => {
					const handleChange = async (value) => {
						const results = await geocodeByAddress(value);
						const formattedFullAddress = results[0].formatted_address;
						const formattedAddressParts = formatAddressAutocomplete(results);
						const formattedAddress = { addressCompleteResults: formattedAddressParts };
						const { addressCompleteResults } = formattedAddress;
						const formValues = getValues();
						const currentField = flatApplication.find((item) => item.id === id);
						if (currentField.uiDisplay?.translateTo) {
							const mingoResults = runMingo(currentField.uiDisplay?.translateTo._calculate, {
								...unFlatten(formValues), variables, addressCompleteResults, offeringData,
							});
							const { value: mingoValue, error: mingoError } = mingoResults;

							if (!mingoError) {
								formatAndReplaceAddressAutocompleteValues(mingoValue, setValue);
								// set the actual field value to the fully formatted address
								field.onChange(formattedFullAddress);
							} else {
								// eslint-disable-next-line no-console
								console.log(`There was a mingo ${mingoError}`);
							}
						} else {
							const fieldsObjectToCreate = {
								'customer.address.line1': `${formattedAddressParts.street_number.short_name} ${formattedAddressParts.route.short_name}` || '',
								'customer.address.line2': formattedAddressParts.subpremise?.short_name || '',
								'customer.address.city': formattedAddressParts.locality.short_name || '',
								'customer.address.state': formattedAddressParts.administrative_area_level_1.short_name || '',
								'customer.address.postalCode': formattedAddressParts.postal_code.short_name || '',
								'customer.address.country': formattedAddressParts.country.short_name || '',
							};
							formatAndReplaceAddressAutocompleteValues(fieldsObjectToCreate, setValue);
						}
						clearErrors(id);
						updateAppState();
						trigger(valuesToWatch);
					};

					return (
						<PlacesAutocomplete
							value={field.value}
							onChange={field.onChange}
							onSelect={handleChange}
							onError={onError}
							shouldFetchSuggestions={field.value.length > 2}
							googleCallbackName="myCallbackFunc"
						>
							{({
								getInputProps, suggestions, getSuggestionItemProps, loading,
							}) => (
								<>
									<input
										id={htmlId}
										className={`input-text${errors ? ' input-invalid' : ''} disabled:cursor-not-allowed`}
										data-testid="address-autocomplete-input"
										type="text"
										aria-required={isRequired}
										{...register(id)}
										onBlur={() => {
											field.onBlur();
											eventCallback('onBlur', { timestamp: Date.now(), elementId: label });
										}}
										onFocus={() => eventCallback('onFocus', { timestamp: Date.now(), elementId: label })}
										disabled={isDisabled}
										{...rest}
										{...getInputProps({ placeholder: 'Type address' })}
									/>

									<div data-testid="suggestions" id="suggestions">
										{loading ? <div>...loading</div> : null}

										{suggestions.map((suggestion) => {
											const style = {
												backgroundColor: suggestion.active ? '#ececec' : '#FFF',
											};

											return (
												<div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
													{suggestion.description}
												</div>
											);
										})}
									</div>
								</>
							)}
						</PlacesAutocomplete>
					);
				}}
			/>

			{hasErrors ? (
				<p className="input-error" data-testid="error-message">
					{errorMessage}
				</p>
			) : null}
		</>
	);
};

export default AddressAutocomplete;
