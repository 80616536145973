import { useEffect, useRef } from 'react';
import { useFormState } from 'react-hook-form';
import { ION_TO_JOI, flattenApplicationFields } from '@buddy-technology/ion-helpers';
import {
	ACTION_TYPES, ACTION_SUB_TYPES, CALL_STATUSES, NAV_ACTIONS,
} from '../models/dictionary';
import { useCallContext, useEventContext, useDataContext } from '../context';
import usePostMessage from './usePostMessage';
import {
	convertFieldIDtoRHF,
	createSelectivePayload,
	handleReactions,
	flattenObj,
} from '../utils';
import logInfo from '../utils/logInfo';

const DEFAULT_RETURN = {
	onNext: null,
	isLoading: false,
	disableBack: false,
	shouldSkip: false,
};

const useAction = (action = {}, actionOptions = {}) => {
	const { debug } = useDataContext();
	const {
		navigate,
		validateFields,
		control,
		// viewType, <-- will need for long-form view
		getValues,
		iframeSrc,
		partnerId,
		ionId,
		stage,
	} = actionOptions;
	const {
		actionType,
		type,
		id,
		allowNext,
		allowBack,
		onSuccess,
		onError,
		onTimeout,
		requiredFields = [],
		optionalFields = [],
		isSuccessWhen,
		timeout,
	} = action;

	const {
		calls, executeCall, updateCall,
	} = useCallContext();

	const timeoutIdRef = useRef();
	const { onCustomMessage } = useEventContext();

	const rawActionFields = [...optionalFields, ...requiredFields];
	const actionFields = rawActionFields.map((el) => convertFieldIDtoRHF(el));
	const call = calls.value.find(({ id: callId }) => id === callId) || {};

	// subscribe to needed form fields (if they error, we don't trigger calls)
	useFormState({ control, name: actionFields });

	const actionFieldValues = getValues(actionFields) || [];

	const runActionHandlers = ({
		reactions,
		error,
		data,
		source,
		origin,
	}) => {
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
		}
		const isNonTriggerPostmessage = (actionType === ACTION_TYPES.POST_MESSAGE) && (type !== ACTION_SUB_TYPES.TRIGGER);

		const defaultSuccessReaction = isNonTriggerPostmessage ? () => {} : () => navigate(NAV_ACTIONS.NEXT);

		// for postmessage actions that are not triggers, we don't want to navigate on success as default
		// eslint-disable-next-line no-console
		const defaultReaction = error ? () => console.log(error) : defaultSuccessReaction;

		handleReactions({
			reactions,
			data,
			defaultReaction,
			origin,
			source,
			...actionOptions,
		});
	};

	const handleReceivedPostMessage = ({ data, origin, source }) => {
		const successFields = isSuccessWhen?.fields || [];
		const schema = ION_TO_JOI(flattenApplicationFields(successFields, '.'));
		const { error } = schema.validate(flattenObj({ data }), {
			abortEarly: true,
			allowUnknown: true,
		});
		const reactions = error ? onError : onSuccess;

		runActionHandlers({
			reactions,
			error,
			data,
			origin,
			source,
		});
	};

	// only listen for our parent if we the ion specifically stated a RESOLVE action, AND there's no iframe obj.
	const shouldListenForParent = (actionType === ACTION_TYPES.POST_MESSAGE)
		&& (type === ACTION_SUB_TYPES.RESOLVE)
		&& !iframeSrc;

	usePostMessage({
		hasAction: actionType === ACTION_TYPES.POST_MESSAGE,
		src: iframeSrc,
		parent: shouldListenForParent,
		callback: handleReceivedPostMessage,
	});

	const getPayload = async () => {
		const shouldSend = await validateFields(actionFields);
		if (shouldSend) {
			return createSelectivePayload(getValues(), [...actionFields, 'session::channelUrl']);
		}
		return null;
	};

	const sendPostMessage = async () => {
		const data = await getPayload();
		if (data) {
			const payload = { id, data };
			if (debug) {
				logInfo('sending post message:', payload);
			}
			onCustomMessage(payload);

			// no error possible when just sending messages
			runActionHandlers({
				reactions: onSuccess,
				data: { payload },
			});
		}
	};

	const sendCall = async () => {
		const shouldSend = await validateFields(actionFields);
		if (shouldSend) {
			const payload = createSelectivePayload(getValues(), actionFields);
			executeCall({
				ionId,
				callId: id,
				payload,
				partnerId,
				stage,
			});

			if (allowNext) {
				navigate(NAV_ACTIONS.NEXT);
			}
		}
	};

	const sendCallOrMessage = actionType === ACTION_TYPES.CALL ? sendCall : sendPostMessage;

	useEffect(() => {
		if ([ACTION_TYPES.CALL, ACTION_TYPES.POST_MESSAGE].includes(actionType) && type === ACTION_SUB_TYPES.LOAD) {
			sendCallOrMessage();
		}
	}, [...actionFieldValues]);

	// TODO: set up async views for long-form view modes.
	// We only want to return the cleanup function if handleReactions runs, so disabling this rule here.
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const isHandled = !!call.handled;
		const isSuccess = call.status === CALL_STATUSES.RESOLVED;
		const isError = call.status === CALL_STATUSES.ERROR;
		const callNeedsHandling = !isHandled && (isSuccess || isError);

		// if we have a timeout, set it up (for all action types, not just calls)
		if (timeout) {
			timeoutIdRef.current = setTimeout(() => {
				if (call) {
					updateCall(call.id, { status: CALL_STATUSES.ERROR });
				}
				const response = { error: `Action [${id}] timed out.` };
				runActionHandlers({
					// default to onError if no onTimeout is provided
					reactions: onTimeout || onError,
					data: { response },
					error: response.error,
				});
				if (debug) {
					logInfo(response.error);
				}
			}, timeout);
		}

		if (callNeedsHandling) {
			const reactions = isSuccess ? onSuccess : onError;
			const response = isSuccess ? call.response : { error: call.error };

			runActionHandlers({
				reactions,
				data: { response },
				isSuccess,
			});

			if (debug) {
				logInfo('call resolved:', { call });
			}
		}

		return () => {
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			// update call to handled onUnmount
			if (callNeedsHandling) {
				updateCall(call.id, { handled: true });
			}
		};
	}, [call.status, timeout]);

	// shallow copy ok here since object is flat
	const returnValues = {
		...DEFAULT_RETURN,
	};

	returnValues.shouldSkip = !!(actionType === ACTION_TYPES.CALL && type === ACTION_SUB_TYPES.RESOLVE && call.handled);

	if (type === ACTION_SUB_TYPES.TRIGGER) {
		returnValues.onNext = sendCallOrMessage;
	}

	if (actionType === ACTION_TYPES.CALL) {
		const isCallPending = call.status === CALL_STATUSES.PENDING;
		returnValues.isLoading = !allowNext && isCallPending;
		// allowBack needs to be explicitly false to disable
		returnValues.disableBack = (allowBack === false) && isCallPending;
	}
	return returnValues;
};

export default useAction;
