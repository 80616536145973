import { convertCalculationsToObjects, substituteVars } from '@buddy-technology/ion-helpers';
import setUpViews from './setUpViews';
// exporting this for testing
// This is to convert conditional key names to be compatible with ITR's state format
// swaps dot notation to use '::'
export const convertRuleConditionKeyNames = (ion) => {
	if (!ion) {
		return null;
	}
	const deepMap = (entity) => {
		const returnVal = Array.isArray(entity) ? [] : {};
		Object.keys(entity).forEach((key) => {
			if (entity[key] && typeof entity[key] === 'object') {
				returnVal[key] = deepMap(entity[key]);
			} else if (key === 'key' && typeof entity[key] === 'string') {
				returnVal[key] = entity[key].replace(/\./g, '::');
			} else {
				returnVal[key] = entity[key];
			}
		});
		return returnVal;
	};
	return deepMap(ion);
};

const prepareION = (rawION) => {
	const converted = convertCalculationsToObjects(rawION);
	const subbed = substituteVars(converted);
	const convertedRulesIon = convertRuleConditionKeyNames(subbed);
	return setUpViews(convertedRulesIon);
};

export default prepareION;
