import React, { createContext, useContext, useMemo } from 'react';

export const DataContext = createContext();

export const DataProvider = ({
	children,
	ion,
	debug,
	offeringOptions,
	sessionId,
}) => {
	const dataValue = useMemo(() => ({
		offeringOptions,
		ion,
		sessionId,
		debug,
	}), []);
	return (
		<DataContext.Provider value={dataValue}>
			{children}
		</DataContext.Provider>
	);
};

export const useDataContext = () => {
	const context = useContext(DataContext);
	if (!context) {
		throw new Error('useDataContext must be used within a DataProvider');
	}
	return context;
};
